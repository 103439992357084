import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import Carro1 from "../../../../../assets/img/carro-azul-grande.png";
import Carro2 from "../../../../../assets/img/carro-azul.png";
import Tractor1 from "../../../../../assets/img/tractor.png";
import Tractor2 from "../../../../../assets/img/tractor-rojo.png";
import LogoRemocar from "../../../../../assets/img/remocar-logo.png";

import Marcas from "./Marcas";

import "./PaginaInicioGrid.css";

const PaginaInicio = () => {
    // const navigate = useNavigate();
    // const usuarioLogueado = localStorage.getItem("token");

    // useEffect(() => {
    //     if (usuarioLogueado) navigate("/");
    // }, []);

    return (
        <div className="background-container contenedor-principal-item-slider">
            {/* <img src={Tractor1} alt="tractor amarillo" className="item-1" />
            <img src={Carro1} alt="carro azul" className="item-2" />            
            <img src={Carro2} alt="tractor amarillo" className="item-4" />
            <img src={Tractor2} alt="tractor rojo" className="item-5" />
            <Marcas /> */}
            <img src={LogoRemocar} alt="remocar logo png" className="item-3" />
            <NavLink to="/login" exact className="btn-login-inicio">
                Log in
            </NavLink>
        </div>
    );
};

export default PaginaInicio;
